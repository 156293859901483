import React from 'react';
import Routes from './config/Routes';
import GlobalStyles from './config/GlobalStyles';
import HeaderTags from './HeaderTags';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './common/queryClient';
import { useAccessByToken } from 'hooks/useAccessByToken';

function App() {
  useAccessByToken();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <HeaderTags />
        <GlobalStyles />
        <Routes />
      </QueryClientProvider>
    </>
  );
}

export default App;

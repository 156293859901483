import { parse } from 'qs';
import { useMemo } from 'react';

export const useQueryParams = () => {
  const queryParams = useMemo(
    () => parse(window.location.search.replace(/^\?/, '')),
    []
  );

  return queryParams;
};

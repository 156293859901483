import { useState, useCallback } from 'react';
import pipeFns from 'utils/pipeFns';

function useSelection(initialState, onClick) {
  const [selected, setSelected] = useState(initialState);

  const isSelected = useCallback(
    path => path.match(new RegExp(selected, 'g')),
    [selected]
  );

  const handleClick = useCallback(pipeFns(setSelected, onClick), [onClick]);

  return [isSelected, handleClick];
}

export default useSelection;

// TODO: add more cases besides dash to pascal. Improve regex and reduce replace chain
function toPascalCase(string = '') {
  return string
    .trim()
    .replace('-', ' ')
    .replace(/(?:^|\s)(\w)/g, m => m.toUpperCase())
    .replace(' ', '');
}

export default toPascalCase;

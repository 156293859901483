import { useState, useCallback, useEffect, useReducer, useRef } from 'react';
import axios from 'axios';
import { baseUrl } from 'utils/env';

function useEndpoint(initialEndpoint, initialOptions) {
  const {
    initialData,
    onMount,
    onSuccessAction,
    onFinally = v => v,
    onSuccess = v => v,
    onResponse = v => v,
    ...initialParams
  } = mountParams(initialOptions);

  const initialState = {
    isLoading: false,
    isError: false,
    data: initialData,
    error: null,
  };

  const didMountRef = useRef(onMount);
  const [endpoint, setEndpoint] = useState(initialEndpoint);
  const [params, setParams] = useState(JSON.stringify(initialParams));
  const [state, dispatch] = useReducer(reducer, initialState);
  const _onFinally = useCallback(onFinally, []);
  const _onSuccess = useCallback(onSuccess, []);
  const _onResponse = useCallback(onResponse, []);

  useEffect(() => {
    let didUnmount = false;

    const fetchData = async () => {
      dispatch({ type: 'request' });

      try {
        const requestParams = JSON.parse(params);

        const keyParams = Object.keys(requestParams.params || {});

        let paramsObj = {};
        for (let i = 0; i < keyParams.length; i += 1) {
          const key = keyParams[i];
          const value = requestParams.params[key];

          if (value) {
            paramsObj[key] = value;
          }
        }

        requestParams.params = paramsObj;

        let result;

        result = await request(`${baseUrl}${endpoint}`, requestParams);

        if (!didUnmount) {
          if (result.data.error || result.data.status === 'refused') {
            // Throw flat error object as possible
            throw result.data.error || result.data;
          } else {
            // External modifications into result.data whenever possible
            const payload = _onResponse(result.data);

            _onSuccess(payload);

            const isPagination = !!(
              requestParams.params && requestParams.params.page
            );

            dispatch({
              type: isPagination ? 'append' : 'success',
              payload,
            });
          }
        }
      } catch (error) {
        if (!didUnmount) {
          dispatch({ type: 'failure', payload: handleError(error) });
        }
      } finally {
        _onFinally();
      }
    };

    if (didMountRef.current) {
      fetchData();
    } else {
      didMountRef.current = true;
    }

    return () => {
      didUnmount = true;
    };
  }, [_onFinally, _onSuccess, _onResponse, onSuccessAction, endpoint, params]);

  const doFetch = {
    endpoint: endpoint => setEndpoint(endpoint),
    body: params => {
      setParams(prev => {
        const prevState = JSON.parse(prev);

        return JSON.stringify({
          ...prevState,
          ...params,
        });
      });
    },
    params: params => {
      setParams(prev => {
        const prevState = JSON.parse(prev);

        let currParams =
          typeof params === 'function'
            ? params(prevState.params || {})
            : params;

        return JSON.stringify({
          ...prevState,
          params: {
            ...prevState.params,
            ...currParams,
          },
        });
      });
    },
    cleanup: () => dispatch({ type: 'cleanup', payload: initialState }),
  };

  return [{ ...state, params: JSON.parse(params) }, doFetch];
}

function reducer(state, action) {
  switch (action.type) {
    case 'request':
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
      };
    case 'append':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: state.data ? [...state.data, ...action.payload] : action.payload,
      };
    case 'success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'failure':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case 'cleanup':
      return action.payload;
    default:
      return state;
  }
}

function mountParams(params) {
  let safeParams = {};

  // Only use params if is indeed an object
  if (typeof params === 'object' && params !== null) {
    safeParams = params;
  }

  return {
    initialData: null,
    onMount: true,
    method: 'GET',
    ...safeParams,
  };
}

function handleError(error) {
  // TODO: improve error messages comming from backend
  if (
    error.message === 'Network Error' ||
    error.message === 'Failed to fetch'
  ) {
    return {
      ...error,
      message:
        'Estamos com problemas para processar seu pagamento. Tente novamente em instantes.',
    };
  }

  if (error.message === 'Request failed with status code 401') {
    return {
      ...error,
      message: 'E-mail ou senha inválidos',
    };
  }

  if (error.status === 'refused') {
    return {
      ...error,
      message:
        'Sua inscrição foi rejeitada pela administradora do seu cartão de crédito. Para te ajudar temos algumas sugestões que podem ser úteis.|Verifique se seu cartão está com o limite liberado ou com saldo suficiente|Entre em contato com a administradora do seu cartão e peça ajuda|Se tiver outro cartão de crédito tente efetuar sua inscrição com ele',
    };
  }

  return error;
}

function validateStatus(status) {
  // Resolve requests within 200 and 300 and 500 (pagarme only)
  return (status >= 200 && status < 300) || status === 500;
}

function request(url, params) {
  const { method, body, ...rest } = params;

  const axiosFn = axios[method.toLowerCase()];

  if (body) {
    return axiosFn(url, body, { ...rest, validateStatus });
  }

  return axiosFn(url, { ...rest, validateStatus });
}

export default useEndpoint;

import styled, { css } from 'styled-components';

const tabBarHeight = 60;

export const ContainerRouter = styled.div`
  margin: ${tabBarHeight}px 0 0 0;

  /* TabBar component */
  > div:first-child {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  @media screen and (max-width: 650px) {
    margin: 0 0 ${tabBarHeight}px 0;

    /* TabBar component */
    > div:first-child {
      top: auto;
      bottom: 0;
      z-index: 1;
    }
  }
`;

export const Container = styled.div`
  background-color: #2b2b2b;
  height: ${tabBarHeight}px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  padding-left: 50px;
  > * ~ * {
    margin-left: 50px;
  }

  @media screen and (max-width: 800px) {
    padding-left: 35px;
    > * ~ * {
      margin-left: 35px;
    }
  }

  @media screen and (max-width: 650px) {
    justify-content: space-around;

    padding-left: 0px;
    > * ~ * {
      margin-left: 0px;
    }
  }
`;

export const ContainerTouchable = styled.button`
  cursor: pointer;
  border: none;
  background-color: #2b2b2b;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  ${props =>
    props.toRight &&
    css`
      @media screen and (min-width: 650px) {
        margin-left: auto;
        margin-right: 50px;
      }
    `};
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.color};

  padding-left: 5px;
  @media screen and (max-width: 800px) {
    padding-left: 0px;
  }
`;

const convertLuminosity = luminosity => {
  // convert percent string number or number -100 to 100
  // into float number between -1.00 and +1.00
  const lum =
    (typeof luminosity === 'string'
      ? parseFloat(luminosity.replace(/%/i, ''), 10)
      : luminosity) / 100;

  // when overflows truncate number between -1 and 1
  return Math.max(Math.min(lum, 1), -1);
};

export const withBrightness = (color, luminosity = 0) => {
  // omit # from hexColor
  const hex = color.replace(/^\s*#|\s*$/g, '');

  const lum = convertLuminosity(luminosity);

  // increse/decrease luminosity
  let rgb = '#';

  for (let c, i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export default {
  lightBlack: '#555555',
  mediumBlack: '#2b2b2b',
  statusGrey: '#dddddd',
  statusGreen: '#88CC88',
  statusRed: '#D46A6A',
  transparent: 'transparent',
  orange: '#ff4a00',
  blue: '#2686ff',
  darkBlue: '#1a62bc',
  lightGrey: '#bbbbbb',
  black: '#000000',
  green: '#478400',
  white: '#ffffff',
  lightWhite: '#f2f2f2',
  lightgreen: '#65cf72',
  superlightgrey: '#f1f1f1',
  footerBlack: '#313131',
  footerTextColor: '#e8e8e8',
  whatsapp_green: '#65cf72',
  facebook_purple: '#4267b2',
  messenger_blue: '#0084ff',
  copiar_link: '#32CD32',
  darkPurple: '#7100b8',
  grey: '#9b9b9b',
  darkGrey: '#777777',
};

function useStyle(style) {
  return (params = {}, condition = true) => {
    if (typeof params === 'boolean') {
      return params ? style({}) : ``;
    }
    return condition ? style(params) : ``;
  };
}

export default useStyle;

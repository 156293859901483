import React, { useContext } from 'react';
import identity from 'utils/identity';
import colors from 'utils/colors';
import useSelection from './useSelection';
import { AppContext } from 'components/PrivateRoutes/PrivateRoutes';

import TabBarOption from './TabBarOption';

import { Container } from './styles';

function TabBar({ onChange }) {
  const { tabBarOptions: options } = useContext(AppContext);

  const [is, handleClick] = useSelection(
    global.window ? window.location.pathname : options[0].pathname,
    onChange
  );

  function handleLogout() {
    // FIXME: add a Modal component to Queima Pay Admin
    const isYes = window.confirm('Deseja realmente sair ?');

    if (isYes) {
      logout();
    }
  }

  function logout() {
    localStorage.clear();
    window.location.pathname = '/login';
  }

  return (
    <Container>
      {options.map(option => (
        <TabBarOption
          key={option.label}
          label={option.label}
          pathname={option.pathname}
          iconName={option.iconName}
          iconColor={is(option.pathname) ? colors.orange : colors.grey}
          onClick={() => handleClick(option.pathname)}
        />
      ))}
      <TabBarOption
        label="Sair"
        iconName="exit"
        iconColor={colors.grey}
        onClick={handleLogout}
        toRight
      />
    </Container>
  );
}

TabBar.defaultProps = {
  onChange: identity,
};

export default TabBar;

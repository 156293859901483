import Loadable from 'react-loadable';

const components = {
  Root: Loadable({
    loader: () => import(/* webpackChunkName: "Root" */ '../screens/Root/Root'),
    loading: () => null,
  }),
  Login: Loadable({
    loader: () =>
      import(/* webpackChunkName: "Login" */ '../screens/Login/Login'),
    loading: () => null,
  }),
  Summary: Loadable({
    loader: () =>
      import(/* webpackChunkName: "Summary" */ '../screens/Summary/Summary'),
    loading: () => null,
  }),
  Statements: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "Statements" */ '../screens/Statements/Container'
      ),
    loading: () => null,
  }),
  Sales: Loadable({
    loader: () =>
      import(/* webpackChunkName: "Sales" */ '../screens/Sales/Container'),
    loading: () => null,
  }),
  Links: Loadable({
    loader: () =>
      import(/* webpackChunkName: "Links" */ '../screens/Links/Container'),
    loading: () => null,
  }),
};

function preloadWith(names) {
  for (let i = 0; i < names.length; i += 1) {
    const name = names[i];
    const component = components[name];

    if (component) {
      component.preload();
    } else {
      throw new Error(
        `TypeError: Cannot preload '${name}' of undefined. Are you sure this component exists?`
      );
    }
  }
}

function preload(...keys) {
  if (!keys.length) {
    // Preload all components when array of keys are empty
    preloadWith(Object.keys(components));
  } else {
    preloadWith(keys);
  }
}

const Async = {
  ...components,
  preload,
};

export default Async;

import axios from 'axios';
import { useQuery } from 'react-query';
import { baseUrl } from '../utils/env';

export function useLinks(token) {
  const { data: linksPagarme } = useQuery('linksPagarme', async () => {
    const { data } = await axios.get(`${baseUrl}/admin/links`, {
      headers: {
        Authorization: token,
      },
    });

    return data;
  });

  return [{ data: linksPagarme }];
}

import { isSafariOrFireFox, isDevice } from 'utils/constants';

function isExternalLink(to) {
  return /^https?/g.test(to);
}

function isPhoneLink(to) {
  // eslint-disable-next-line no-useless-escape
  return /^[0-9\s\(\)\+\-]*$/g.test(to);
}

function isEmailLink(to) {
  return /^mailto:/g.test(to);
}

function formatPhoneString(to) {
  const countryCode = 55; // BRL

  // eslint-disable-next-line no-useless-escape
  return `${countryCode}${to.replace(/[\s\(\)\+\-]/g, '')}`;
}

function getPhoneLink(to) {
  const phone = formatPhoneString(to);

  const baseUrl =
    isSafariOrFireFox && !isDevice
      ? `https://web.whatsapp.com`
      : `https://api.whatsapp.com`;

  return `${baseUrl}/send?phone=${phone}`;
}

function getExternalLink(to) {
  let link;

  if (isExternalLink(to) || isEmailLink(to)) {
    link = to;
  } else if (isPhoneLink(to)) {
    link = getPhoneLink(to);
  }

  return link;
}

export default getExternalLink;

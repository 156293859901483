import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/Icon/Icon';

import { ContainerTouchable, Label } from './styles';

function TabBarOption({ label, onClick, iconName, iconColor, toRight }) {
  return (
    <ContainerTouchable onClick={onClick} toRight={toRight}>
      <Icon name={iconName} color={iconColor} width={25} height={25} />
      <Label color={iconColor}>{label}</Label>
    </ContainerTouchable>
  );
}

TabBarOption.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconColor: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  toRight: PropTypes.bool,
};

TabBarOption.defaultProps = {
  toRight: false,
};

export default React.memo(TabBarOption);

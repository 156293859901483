const privateOptions = [
  {
    screenName: 'Summary',
    pathname: '/summary',
    exact: true,
  },
  {
    screenName: 'Statements',
    pathname: '/statements',
    exact: true,
  },
  {
    screenName: 'Sales',
    pathname: '/sales',
    exact: true,
  },
  {
    screenName: 'Links',
    pathname: '/links',
    exact: true,
  },
];

export default privateOptions;

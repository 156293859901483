import { css, keyframes } from 'styled-components';
import useStyle from 'hooks/useStyle';
import colors from 'utils/colors';

export const spinFrame = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const useSize = useStyle(({ size }) => {
  switch (size) {
    case 'large':
      return css`
        border-width: 5px;
        border-style: solid;
        width: 60px;
        height: 60px;
      `;
    case 'medium':
      return css`
        border-width: 4px;
        border-style: solid;
        width: 40px;
        height: 40px;
      `;
    case 'small':
      return css`
        border-width: 3px;
        border-style: solid;
        width: 20px;
        height: 20px;
      `;
    case 'extra-small':
    default:
      return css`
        border-width: 2px;
        border-style: solid;
        width: 12px;
        height: 12px;
      `;
  }
});

export const useTheme = useStyle(({ theme }) => {
  switch (theme) {
    case 'ghost':
      return css`
        border-color: ${colors.white};
        border-top-color: ${colors.blue};
      `;
    case 'primary-orange':
      return css`
        border-color: ${colors.white};
        border-top-color: ${colors.orange};
      `;
    case 'primary':
    default:
      return css`
        border-color: ${colors.white};
        border-top-color: ${colors.blue};
      `;
  }
});

import React, { useCallback } from 'react';
import TabBar from './TabBar';

import { ContainerRouter } from './styles';

function TabBarRouter({ component: Component, ...props }) {
  const onChange = useCallback(props.history.push, [props.history]);

  return (
    <ContainerRouter>
      <TabBar onChange={onChange} />
      <Component {...props} />
    </ContainerRouter>
  );
}

export default TabBarRouter;

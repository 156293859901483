import React from 'react';
import PropTypes from 'prop-types';

function Click({ color, width, height, ariaLabel }) {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 23"
      aria-labelledby="title"
    >
      <title id="title">{ariaLabel}</title>
      <g fill={color} fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M15.906 13.153c0-1.054-.847-1.893-1.859-1.893-.353 0-.659.096-.941.264a1.869 1.869 0 0 0-1.67-1.054c-.354 0-.66.096-.942.263a1.869 1.869 0 0 0-1.67-1.054c-.26 0-.518.048-.753.168V5.966c0-.503-.189-.983-.542-1.342a1.834 1.834 0 0 0-1.317-.551c-1.036 0-1.86.862-1.86 1.917v8.792l-.987-1.006a1.894 1.894 0 0 0-2.4-.24c-.447.312-.73.791-.824 1.318a1.97 1.97 0 0 0 .424 1.51l3.576 4.384a5.7 5.7 0 0 0 4.447 2.132h2.236a5.065 5.065 0 0 0 3.623-1.533 5.252 5.252 0 0 0 1.506-3.69l-.047-4.504zm-2.306 7.38a3.954 3.954 0 0 1-2.824 1.197H8.541c-1.388 0-2.682-.623-3.576-1.725l-3.553-4.384a.782.782 0 0 1-.165-.6.712.712 0 0 1 .33-.526A.697.697 0 0 1 2 14.35c.188 0 .4.072.541.216l1.435 1.461a.822.822 0 0 0 .942.192.884.884 0 0 0 .54-.815v-9.44c0-.407.33-.766.73-.766.188 0 .377.072.518.216a.75.75 0 0 1 .212.527v6.516c0 .312.258.575.564.575a.578.578 0 0 0 .565-.575v-.886c0-.407.33-.767.753-.767.4 0 .753.336.753.767v1.677c0 .311.259.575.565.575a.578.578 0 0 0 .564-.575v-.886c0-.408.33-.767.753-.767.4 0 .753.335.753.767v1.677c0 .311.26.575.565.575a.578.578 0 0 0 .565-.575v-.815-.072a.74.74 0 0 1 .73-.743c.4 0 .728.336.728.743v4.504a4.058 4.058 0 0 1-1.176 2.875z"
        />
        <path d="M6.188 3.163a.578.578 0 0 0 .565-.575V.694A.578.578 0 0 0 6.188.12a.578.578 0 0 0-.564.575v1.892c0 .336.258.575.564.575zM3.459 3.977c.117.12.259.168.4.168a.546.546 0 0 0 .4-.168.602.602 0 0 0 0-.814L2.94 1.82a.576.576 0 0 0-.8 0 .602.602 0 0 0 0 .814L3.46 3.977zM8.518 4.145a.546.546 0 0 0 .4-.168l1.317-1.342a.602.602 0 0 0 0-.814.576.576 0 0 0-.8 0L8.118 3.163a.602.602 0 0 0 0 .814c.117.12.258.168.4.168z" />
      </g>
    </svg>
  );
}

Click.defaultProps = {
  ariaLabel: '',
};

Click.propTypes = {
  ariaLabel: PropTypes.string,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Click;

function pipeFns(...fns) {
  return function callWithArgs(...args) {
    let results = [];

    for (let i = 0; i < fns.length; i += 1) {
      const fn = fns[i];
      if (fn) {
        results.push(fn(...args));
      }
    }

    return results;
  };
}

export default pipeFns;

import React from 'react';
import PropTypes from 'prop-types';

function BarsCurrency({ color, width, height, ariaLabel }) {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 16"
      aria-labelledby="title"
    >
      <title id="title">{ariaLabel}</title>
      <g fill="none" fillRule="evenodd">
        <g fill={color} stroke={color} transform="translate(0 4)">
          <rect width="5" height="1" x="6.5" y="8.5" rx=".5" />
          <rect width="11" height="1" x=".5" y="4.5" rx=".5" />
          <rect width="11" height="1" x=".5" y=".5" rx=".5" />
        </g>
        <path
          fill={color}
          stroke={color}
          strokeWidth=".2"
          d="M17.26 8.026c-1.718-.394-2.527-.69-2.527-1.694 0-.726.27-1.23.823-1.543.962-.543 2.559-.332 3.416.456.32.294.782.23 1.033-.145.25-.372.196-.911-.124-1.203-.587-.539-1.351-.882-2.146-1.034V1.339c0-.474-.33-.858-.736-.858-.406 0-.735.384-.735.858v1.475a3.735 3.735 0 0 0-1.35.432c-1.065.603-1.651 1.698-1.651 3.086 0 2.525 2.146 3.018 3.713 3.377 1.69.388 2.29.654 2.29 1.374 0 .706-.254 1.203-.778 1.518-.923.56-2.468.394-3.375-.356-.332-.272-.79-.183-1.025.204-.234.387-.157.922.175 1.196.563.467 1.27.766 2.001.905v2.051c0 .474.33.858.735.858.407 0 .736-.384.736-.858v-2.028c.512-.067 1.008-.193 1.428-.447 1.015-.611 1.574-1.692 1.574-3.042 0-2.26-1.927-2.703-3.477-3.058z"
        />
      </g>
    </svg>
  );
}

BarsCurrency.defaultProps = {
  ariaLabel: '',
};

BarsCurrency.propTypes = {
  ariaLabel: PropTypes.string,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default BarsCurrency;

import React from 'react';
import PropTypes from 'prop-types';

function Bars({ color, width, height, ariaLabel }) {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 9"
      aria-labelledby="title"
    >
      <title id="title">{ariaLabel}</title>
      <g fill={color} fillRule="evenodd" stroke={color} strokeWidth="0.8">
        <rect width="6" height="1" x="11.5" y="8.5" rx=".5" />
        <rect width="15" height="1" x="2.5" y="4.5" rx=".5" />
        <rect width="15" height="1" x="2.5" y="0.5" rx=".5" />
      </g>
    </svg>
  );
}

Bars.defaultProps = {
  ariaLabel: '',
};

Bars.propTypes = {
  ariaLabel: PropTypes.string,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Bars;

import React from 'react';
import PropTypes from 'prop-types';

function Exit({ color, width, height, ariaLabel }) {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 16"
      aria-labelledby="title"
    >
      <title id="title">{ariaLabel}</title>
      <g fill={color} fillRule="evenodd">
        <path d="M2.14 18h7.235c1.19 0 2.161-.97 2.161-2.141v-1.893a.535.535 0 0 0-.539-.53.535.535 0 0 0-.539.53v1.893a1.09 1.09 0 0 1-1.083 1.08H2.141a1.073 1.073 0 0 1-1.063-1.08V2.129c-.003-.28.106-.55.304-.75.199-.2.47-.314.753-.317h7.24a1.08 1.08 0 0 1 1.083 1.066v1.853c0 .294.242.531.54.531.297 0 .538-.237.538-.53V2.127C11.533.954 10.567.003 9.376 0H2.14A2.166 2.166 0 0 0 .624.626 2.1 2.1 0 0 0 0 2.126v13.733C0 17.029.952 18 2.14 18z" />
        <path d="M18.111 8.442H5.353a.535.535 0 0 0-.539.531c0 .294.241.531.54.531H18.23l-2.766 3.15a.532.532 0 0 0 .054.753c.224.193.565.17.76-.05l3.487-3.955a.526.526 0 0 0-.026-.837L16.254 5.13a.545.545 0 0 0-.763 0 .515.515 0 0 0 0 .74l2.62 2.571z" />
      </g>
    </svg>
  );
}

Exit.defaultProps = {
  ariaLabel: '',
};

Exit.propTypes = {
  ariaLabel: PropTypes.string,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Exit;

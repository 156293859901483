const tabBarOptions = [
  {
    label: 'Resumo',
    pathname: '/summary',
    iconName: 'chart',
  },
  {
    label: 'Extrato',
    pathname: '/statements',
    iconName: 'bars',
  },
  {
    label: 'Gestão',
    pathname: '/sales',
    iconName: 'bars-currency',
  },
  {
    label: 'Links',
    pathname: '/links',
    iconName: 'copy',
  },
];

export default tabBarOptions;

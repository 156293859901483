import React from 'react';
import PropTypes from 'prop-types';

function Chart({ color, width, height, ariaLabel }) {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 19"
      aria-labelledby="title"
    >
      <title id="title">{ariaLabel}</title>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
      >
        <path d="M1.144 1.098v16.804h19.592M15.633 7.806l-3.61 4.71M10.52 12.728L7.912 10.85M6.387 11.063L1.146 17.9" />
        <path d="M8.118 10.238c0 .229-.078.438-.207.613-.194.258-.512.43-.87.43-.245 0-.473-.08-.654-.218a1.017 1.017 0 0 1-.422-.825c0-.576.482-1.043 1.076-1.043.595 0 1.077.467 1.077 1.043zM12.459 13.35c0 .58-.482 1.042-1.077 1.042-.594 0-1.076-.463-1.076-1.043 0-.233.077-.446.215-.621.194-.255.508-.421.861-.421.241 0 .461.075.642.208.263.188.435.492.435.834zM17.351 6.971c0 .576-.482 1.043-1.077 1.043a1.07 1.07 0 0 1-.641-.208 1.027 1.027 0 0 1-.435-.835c0-.575.482-1.042 1.076-1.042.242 0 .461.075.642.204.263.192.435.496.435.838zM19.526 2.491l-2.631 3.63M16.718 2.056l3.559-.56.579 3.447M20.277 1.496l-.848 1.126" />
      </g>
    </svg>
  );
}

Chart.defaultProps = {
  ariaLabel: '',
};

Chart.propTypes = {
  ariaLabel: PropTypes.string,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Chart;

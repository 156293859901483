import React from 'react';
import PropTypes from 'prop-types';
import toPascalCase from 'utils/toPascalCase';

import * as Factory from './Factory';

function Icon({ name, ...iconProps }) {
  const factoryIconName = toPascalCase(name);

  const FactoryIcon = Factory[factoryIconName];

  if (!FactoryIcon) {
    throw new Error(`TypeError: Cannot find ${factoryIconName} of undefined`);
  }

  return <FactoryIcon {...iconProps} />;
}

Icon.propTypes = {
  ariaLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Icon.defaultProps = {
  ariaLabel: '',
};

export default Icon;

import React from 'react';
import useEndpoint from 'hooks/useEndpoint';
import colors from 'utils/colors';
import privateOptions from 'config/privateOptions';
import tabBarOptions from 'config/tabBarOptions';

import Redirect from 'common/Redirect/Redirect';
import Loader from 'common/Loader/Loader';
import { useLinks } from 'hooks/useLinks';

export const AppContext = React.createContext(null);

function PrivateRoutes({ children }) {
  const token = localStorage.getItem('$$token');

  const [userdata] = useEndpoint('/admin/current-user', {
    headers: {
      Authorization: token,
    },
  });

  const [links] = useLinks(token);

  if (!token || userdata.isError || links.isError) {
    return <Redirect to="/login" />;
  }

  if (!userdata.data || !links.data) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: colors.mediumBlack,
          padding: '15px',
          borderRadius: '15px',
          zIndex: 2,
        }}
      >
        <Loader
          theme="primary-orange"
          size="medium"
          style={{ alignSelf: 'center' }}
        />
      </div>
    );
  }

  const options = getUserTabAndRouteOptions(links.data.length);

  const appState = {
    token,
    links: links.data,
    ...options,
    ...userdata.data,
  };

  return (
    <AppContext.Provider value={appState}>
      {children(options.privateOptions)}
    </AppContext.Provider>
  );
}

// TODO: use this function in a more generic context instead of only filter Links
function getUserTabAndRouteOptions(hasLinks) {
  if (hasLinks) {
    return {
      privateOptions,
      tabBarOptions,
    };
  }

  function removeLinksOption(options) {
    return options.filter(option => option.pathname !== '/links');
  }

  return {
    privateOptions: removeLinksOption(privateOptions),
    tabBarOptions: removeLinksOption(tabBarOptions),
  };
}

export default PrivateRoutes;

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect as RouterRedirect } from 'react-router-dom';

import getExternalLink from './getExternalLink';

function Redirect({ to, children, style }) {
  const href = getExternalLink(to);

  if (href) {
    return (
      <a href={href} style={style} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return <RouterRedirect to={to} />;
}

Redirect.defaultProps = {
  children: null,
  style: {},
};

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
};

// Once instanciate will never be modified
export default React.memo(Redirect);

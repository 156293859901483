const publicOptions = [
  {
    screenName: 'Root',
    pathname: '/',
    exact: true,
  },
  {
    screenName: 'Login',
    pathname: '/login',
    exact: true,
  },
];

export default publicOptions;

const phones = {
  MEMBER: '(31) 98222-5779',
  NOT_MEMBER: '(31) 99154-2158',
};

const paymentEnum = {
  PROCESSING: 'processing',
  AUTHORIZED: 'authorized',
  PAID: 'paid',
  REFUNDED: 'refunded',
  WAITING_PAYMENT: 'waiting_payment',
  PENDING_REFUND: 'pending_refund',
  REFUSED: 'refused',
  YEAR_SUBSCRIPTION: 'yearly',
  YEAR_PERIOD: 365,
  MONTH_SUBSCRIPTION: 'monthly',
  MONTH_PERIOD: 30,
  BILLET_PAYMENT: 'boleto',
  CREDIT_CARD_PAYMENT: 'credit_card',
  DEFAULT_CURRENCY: 'BRL',
  DEFAULT_CATEGORY: 'Queima Diária',
};

const testUserAgent = reg => reg.test(navigator.userAgent);

const isDevice = testUserAgent(
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i
);

const isDesktop = !isDevice;

const isSafariOrFireFox = testUserAgent(/Safari|Firefox/i);

export { phones, paymentEnum, isDesktop, isDevice, isSafariOrFireFox };

import styled from 'styled-components';
import colors from 'utils/colors';
import { useSize, useTheme, spinFrame } from './hooks';

const Loader = styled.div`
  animation: 0.8s linear ${spinFrame} infinite;
  border-radius: 50%;

  ${useSize};
  ${useTheme};
`;

Loader.defaultProps = {
  size: 'small',
  backgroundColor: colors.white,
  color: colors.blue,
};

export default Loader;

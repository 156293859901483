import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Async from './Async';
import TabBarRouter from 'components/TabBar/TabBarRouter';
import PrivateRoutes from 'components/PrivateRoutes/PrivateRoutes';
import publicOptions from './publicOptions';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {publicOptions.map(({ screenName, pathname, ...rest }) => (
          <Route
            key={screenName}
            path={pathname}
            component={Async[screenName]}
            {...rest}
          />
        ))}
        <PrivateRoutes>
          {privateOptions =>
            privateOptions.map(({ screenName, pathname, ...rest }) => (
              <Route
                key={screenName}
                path={pathname}
                render={props => (
                  <TabBarRouter component={Async[screenName]} {...props} />
                )}
                {...rest}
              />
            ))
          }
        </PrivateRoutes>
      </Switch>
    </BrowserRouter>
  );
}

export default React.memo(Routes);

export const {
  REACT_APP_ASSETS_URL: baseAssetsUrl,
  REACT_APP_API_MICROSERVICE_URL: baseUrl,
  REACT_APP_NODE_ENV_SHORT_NAME: envShortName,
  REACT_APP_VERSION: appVersion,
  REACT_APP_GOOGLE_ANALYTICS_ID: googleAnalyticsID,
  REACT_APP_API_ADYEN_MICROSERVICE_URL: baseUrlAdyen,
} = process.env;

function isProductionEnv() {
  // create-react-app will always resolve build to production env
  // so to create a controlled "prod" environment even if production
  // build always resolve NODE_ENV to "prod" envShortName variable was created.
  return envShortName === 'prod';
}

function isDevelopmentEnv() {
  /// create-react-app will always resolve build to production env
  // so to create a controlled "dev" environment even if production
  // build always resolve NODE_ENV to "prod" envShortName variable was created.
  return envShortName === 'dev';
}

function isTestEnv() {
  return process.env.NODE_ENV === 'test';
}

export { isProductionEnv, isDevelopmentEnv, isTestEnv };

import React from 'react';
import PropTypes from 'prop-types';

function Copy({ color, width, height, ariaLabel }) {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 16"
      aria-labelledby="title"
    >
      <title id="title">{ariaLabel}</title>
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.212 0H7.058a1.792 1.792 0 0 0-1.789 1.788V5.27H2.015C.904 5.27.002 6.17 0 7.28v7.705C0 16.098.902 17 2.015 17H9.72a2.015 2.015 0 0 0 2.01-2.015v-3.254h3.482A1.792 1.792 0 0 0 17 9.942V1.788A1.792 1.792 0 0 0 15.212 0zm-4.673 14.985c0 .453-.366.82-.819.823H2.015a.823.823 0 0 1-.823-.823V7.28a.823.823 0 0 1 .823-.819h3.254v3.481a1.792 1.792 0 0 0 1.789 1.789h3.48v3.254zm5.269-5.043c0 .33-.267.597-.596.597H7.058a.596.596 0 0 1-.597-.597V1.788c0-.329.267-.596.597-.596h8.154a.6.6 0 0 1 .596.596v8.154z"
      />
    </svg>
  );
}

Copy.defaultProps = {
  ariaLabel: '',
};

Copy.propTypes = {
  ariaLabel: PropTypes.string,
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Copy;
